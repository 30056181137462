import './soppe.scss';

import '@mod-mso_pixelarity/webdesigns/bedum/bedum';

import * as dompack from 'dompack';

import './pages/blog/blog';
import './pages/lawyers/lawyers';
import './pages/overview/overview';
import './pages/projects/projects';
import './pages/publications/publications';
import './pages/search/';
